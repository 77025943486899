const teacherRoutes = [
  {
    path: "/team/",
    name: "Index",
    redirect: {
      name: "TeacherDashboard",
    },
  },
  {
    path: "/teacher/",
    name: "TeacherInfo",
    component: () =>
      import(
        /* webpackChunkName: "TeacherInfo" */ "../../views/teacher/TeacherInfo.vue"
      ),
  },
  {
    path: "/teacher/login",
    name: "TeacherLogin",
    component: () =>
      import(
        /* webpackChunkName: "TeacherLogin" */ "../../views/teacher/Login.vue"
      ),
  },
  {
    path: "/teacher/dashboard",
    name: "TeacherDashboard",
    component: () =>
      import(
        /* webpackChunkName: "TeacherDashboard" */ "../../views/teacher/Dashboard.vue"
      ),
  },
];
export default teacherRoutes;
