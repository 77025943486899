import Vue from "vue";
import VueRouter from "vue-router";
import Nprogress from "nprogress";
import "nprogress/nprogress.css";
import teacherRoutes from "./routes/teacher";
import appRoutes from "./routes/app";
import websiteRoutes from "./routes/website";
import NotFound from "../views/website/NotFound";

Vue.use(VueRouter);

const notFoundRoutes = [{ path: "*", component: NotFound }];

const routes = appRoutes
  .concat(teacherRoutes)
  .concat(websiteRoutes)
  .concat(notFoundRoutes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(async (to, _from, next) => {
//   // check if route is marked as requiresAuth in metadata
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (localStorage.accessToken) {
//       next();
//     } else {
//       Notification.error("Unauthorized");
//     }
//   } else {
//     next();
//   }
// });

router.beforeResolve((to, _from, next) => {
  // enforce https on production
  if (process.env.VUE_APP_ENV && process.env.VUE_APP_ENV == "prod") {
    const url = window.location.href;
    if (url.slice(0, 5) != "https") {
      window.location.href = "https:" + url.slice(5, url.length + 1);
    }
  }
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    Nprogress.start();
  }
  next();
});

router.afterEach(() => {
  window.scrollTo(0, 0);
  // Complete the animation of the route progress bar.
  Nprogress.done();
});

export default router;
