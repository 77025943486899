<template>
  <div v-if="!navbarHidden" class="navbar">
    <b-navbar :mobile-burger="true" class="navbar-body py-3">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ name: 'Home' }">
          <img
            src="@/assets/img/logo.svg"
            alt="TeachQuran"
            style="max-height: 100rem"
          />
          <b-tag
            v-if="currentUser && currentUser.type === 'teacher'"
            type="is-dark"
            class="ml-4"
            >Teachers Portal</b-tag
          >
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item tag="router-link" :to="{ name: 'DemoClass' }">
          Demo Class
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'CourseOutline' }">
          Course Outline
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'TeacherInfo' }">
          For Teachers
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'FAQ' }">
          FAQs
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'Contact' }">
          Contact Us
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item
          v-if="currentUser && currentUser.type === 'student'"
          tag="div"
        >
          <b-dropdown aria-role="list">
            <template #trigger>
              <b-button
                :label="currentUser.fullName.split(' ')[0]"
                type="is-success is-rounded"
                icon-left="account"
                icon-right="menu-down"
              />
            </template>

            <router-link
              :to="{ name: 'StudentDashboard' }"
              class="is-dark-gray"
            >
              <b-dropdown-item aria-role="listitem">
                <div class="media">
                  <b-icon class="media-left" icon="apps"></b-icon>
                  <div class="media-content">
                    <h3>Dashboard</h3>
                  </div>
                </div>
              </b-dropdown-item>
            </router-link>
            <router-link :to="{ name: 'StudentAccount' }" class="is-dark-gray">
              <b-dropdown-item aria-role="listitem">
                <div class="media">
                  <b-icon class="media-left" icon="account"></b-icon>
                  <div class="media-content">
                    <h3>Account</h3>
                  </div>
                </div>
              </b-dropdown-item>
            </router-link>
            <router-link :to="{ name: 'StudentFeedback' }" class="is-dark-gray">
              <b-dropdown-item aria-role="listitem">
                <div class="media">
                  <b-icon
                    class="media-left"
                    icon="comment-quote-outline"
                  ></b-icon>
                  <div class="media-content">
                    <h3>Feedback</h3>
                  </div>
                </div>
              </b-dropdown-item>
            </router-link>
            <router-link :to="{ name: 'FAQ' }" class="is-dark-gray">
              <b-dropdown-item aria-role="listitem">
                <div class="media">
                  <b-icon
                    class="media-left"
                    icon="comment-question-outline"
                  ></b-icon>
                  <div class="media-content">
                    <h3>Help</h3>
                  </div>
                </div>
              </b-dropdown-item>
            </router-link>
            <hr class="my-2" />
            <router-link :to="{}" class="is-dark-gray">
              <b-dropdown-item @click="logout" aria-role="listitem">
                <div class="media">
                  <b-icon class="media-left" icon="logout-variant"></b-icon>
                  <div class="media-content">
                    <h3>Logout</h3>
                  </div>
                </div>
              </b-dropdown-item>
            </router-link>

            <!-- <hr class="my-2" />
            <router-link :to="{ name: 'StudentDashboard' }" class="is-dark-gray">
              <b-dropdown-item aria-role="listitem">
                <div class="media">
                  <b-icon
                    class="media-left"
                    icon="file-multiple-outline"
                  ></b-icon>
                  <div class="media-content">
                    <h3>Invoices</h3>
                    <small>View your invoices</small>
                  </div>
                </div>
              </b-dropdown-item>
            </router-link>
            <hr class="my-2" />
            <router-link :to="{ name: 'Feedback' }" class="is-dark-gray">
              <b-dropdown-item aria-role="listitem">
                <div class="media">
                  <b-icon
                    class="media-left"
                    icon="comment-quote-outline"
                  ></b-icon>
                  <div class="media-content">
                    <h3>Feedback</h3>
                    <small>Help us improve</small>
                  </div>
                </div>
              </b-dropdown-item>
            </router-link>
            <hr class="my-2" /> -->
          </b-dropdown>
        </b-navbar-item>
        <b-navbar-item
          v-else-if="currentUser && currentUser.type === 'teacher'"
          tag="div"
        >
          <b-dropdown aria-role="list">
            <template #trigger>
              <b-button
                :label="currentUser.fullName"
                type="is-info is-light"
                icon-left="account"
                icon-right="menu-down"
              />
            </template>
            <router-link
              :to="{ name: 'TeacherDashboard' }"
              class="is-dark-gray"
            >
              <b-dropdown-item aria-role="listitem">
                <div class="media">
                  <b-icon class="media-left" icon="apps"></b-icon>
                  <div class="media-content">
                    <h3>Dashboard</h3>
                  </div>
                </div>
              </b-dropdown-item>
            </router-link>
            <hr class="my-2" />
            <b-dropdown-item @click="logout" aria-role="listitem">
              <div class="media">
                <b-icon class="media-left" icon="logout-variant"></b-icon>
                <div class="media-content">
                  <h3>Logout</h3>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </b-navbar-item>
        <b-navbar-item v-else tag="div">
          <div class="buttons">
            <router-link :to="{ name: 'Login' }" class="is-dark-gray">
              <a class="button is-success is-light">Login </a>
            </router-link>
            <router-link :to="{ name: 'Register' }" class="is-dark-gray ml-3">
              <a class="button is-success">Register </a>
            </router-link>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="navbar-offset"></div>
    <div class="teacher-nav"></div>

    <a
      href="https://wa.me/923315033993?text=Assalam%20o%20Alikum%2C%20I%20need%20more%20information%20regarding%20Quran%20classes."
      class="wa-float"
      target="_blank"
    >
      <b-tooltip
        label="Have a Question? Contact us now"
        position="is-left"
        type="is-light"
        class="mr-5"
        always
      >
        <i class="mdi mdi-whatsapp ml-3"></i>
      </b-tooltip>
    </a>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    navbarHidden() {
      return this.$route.query.n;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.wa-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 38px;
  box-shadow: 2px 2px 3px rgba(50, 50, 50, 0.165);
  z-index: 100;
}
.wa-float:hover {
  color: #fff;
  background-color: #22c25d;
}
</style>
