import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import router from "./router";
import store from "./store";
import api from "@/services/dataService";
import "@/common/filters";
import vueCountryRegionSelect from "vue-country-region-select";

Vue.use(vueCountryRegionSelect);
Vue.use(Buefy);

Vue.config.productionTip = false;

if (localStorage.accessToken && localStorage.user) {
  api.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.accessToken;
  store.dispatch("setCurrentUser", JSON.parse(localStorage.user));
} else {
  localStorage.removeItem("user");
  localStorage.removeItem("accessToken");
  api.defaults.headers.common["Authorization"] = undefined;
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
