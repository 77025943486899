const websiteRoutes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "website" */ "../../views/website/Home.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(
        /* webpackChunkName: "website" */ "../../views/website/Contact.vue"
      ),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () =>
      import(/* webpackChunkName: "website" */ "../../views/website/FAQ.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "website" */ "../../views/website/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: () =>
      import(
        /* webpackChunkName: "website" */ "../../views/website/TermsAndConditions.vue"
      ),
  },
  {
    path: "/course/outline",
    name: "CourseOutline",
    component: () =>
      import(
        /* webpackChunkName: "website" */ "../../views/website/CourseOutline.vue"
      ),
  },
  {
    path: "/demo-class",
    name: "DemoClass",
    component: () =>
      import(
        /* webpackChunkName: "website" */ "../../views/website/DemoClass.vue"
      ),
  },
  {
    path: "/teacher/demo-class",
    name: "DemoClassTeacher",
    component: () =>
      import(
        /* webpackChunkName: "website" */ "../../views/website/DemoClass.vue"
      ),
  },
];
export default websiteRoutes;
