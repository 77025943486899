const appRoutes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../../views/app/Login.vue"),
  },
  {
    path: "/signup",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Register" */ "../../views/app/Register.vue"),
  },
  {
    path: "/dashboard",
    name: "StudentDashboard",
    component: () =>
      import(
        /* webpackChunkName: "StudentDashboard" */ "../../views/app/Dashboard.vue"
      ),
  },
  {
    path: "/account",
    name: "StudentAccount",
    component: () =>
      import(
        /* webpackChunkName: "StudentDashboard" */ "../../views/app/Account.vue"
      ),
  },
  {
    path: "/feedback",
    name: "StudentFeedback",
    component: () =>
      import(
        /* webpackChunkName: "StudentDashboard" */ "../../views/app/Feedback.vue"
      ),
  },
];
export default appRoutes;
