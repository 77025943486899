import Vue from "vue";
import { DateTime } from "luxon";

Vue.filter("date", (value, type = "default") => {
  if (value == null) {
    return "-";
  } else if (type == "time") {
    return DateTime.fromISO(value).toFormat("hh:mm a");
  } else if (type == "time-date") {
    return DateTime.fromISO(value).toFormat("hh:mm a, dd/MM/yyyy");
  } else if (type == "year") {
    return DateTime.fromISO(value).toFormat("yyyy");
  } else {
    // Default filter for date
    return DateTime.fromISO(value).toFormat("dd/MM/yyyy");
  }
});

Vue.filter("status", (value, type) => {
  if (type == "teacher") {
    if (value == "needsAttention") {
      return "Awaiting Student Response";
    } else if (value == "inProgress") {
      return "Deliverable Pending";
    } else if (value == "newUpdate") {
      return "Awaiting Student Response";
    } else if (value == "completed") {
      return "Order Completed";
    } else if (value == "processingPayment") {
      return "Payment Pending";
    } else if (value == "submitQuestionnaire") {
      return "Awaiting Responses";
    } else {
      return "-";
    }
  } else if (type == "student") {
    if (value == "needsAttention") {
      return "Needs Attention";
    } else if (value == "inProgress") {
      return "In Progress";
    } else if (value == "newUpdate") {
      return "New Update";
    } else if (value == "completed") {
      return "Order Completed";
    } else if (value == "processingPayment") {
      return "Processing Payment";
    } else if (value == "submitQuestionnaire") {
      return "Submit Questionnaire";
    } else {
      return "-";
    }
  }
});

Vue.filter("formatNull", (value) => {
  if (value == null || value === 0) {
    return "-";
  } else {
    return value;
  }
});

Vue.filter("capitalize", (value) => {
  return value[0].toUpperCase() + value.slice(1, value.length).toLowerCase();
});

Vue.filter("limitTo", (value, limit) => {
  if (value.length <= limit) {
    return value;
  }
  return value.slice(0, limit) + "...";
});

Vue.filter("currency", (value) => {
  let formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "PKR",
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});
