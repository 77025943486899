import Vue from "vue";
import Vuex from "vuex";
import api from "../services/dataService";
import router from "@/router";
import Notification from "@/services/notificationService";

Vue.use(Vuex);
function defaultState() {
  return {
    currentUser: undefined,
    allCourses: undefined,
    allEnrollments: undefined,
    allStudentOrders: undefined,
    allTeacherOrders: undefined,
    orderDetail: undefined,
    projects: undefined,
    allProducts: undefined,
    productDetail: undefined,
    paymentUrl: undefined,
    studentSubscription: undefined,
  };
}

export default new Vuex.Store({
  state: defaultState,
  mutations: {
    setStoreItem(state, payload) {
      state[payload[0]] = payload[1];
    },
  },
  actions: {
    setCurrentUser(state, request) {
      state.commit("setStoreItem", ["currentUser", request]);
    },
    studentLogin(state, request) {
      return api
        .post("/student/signin", request)
        .then((res) => {
          if (res.status === 200) {
            const user = {
              type: "student",
              ...res.data.student,
            };
            api.defaults.headers.common["Authorization"] =
              "Bearer " + res.data.accessToken;
            localStorage.accessToken = res.data.accessToken;
            localStorage.user = JSON.stringify(user);
            state.dispatch("setCurrentUser", user);
            router.push({ name: "StudentDashboard" });
            return true;
          }
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );

          return { errorMessages: err.message };
        });
    },
    studentRegister(state, request) {
      return api
        .post("/student/signup", request)
        .then((res) => {
          if (res.status === 200) {
            const user = {
              type: "student",
              ...res.data.student,
            };
            api.defaults.headers.common["Authorization"] =
              "Bearer " + res.data.accessToken;
            localStorage.accessToken = res.data.accessToken;
            localStorage.user = JSON.stringify(user);
            state.dispatch("setCurrentUser", user);
            router.push({ name: "StudentDashboard" });
            return true;
          }
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );

          return { errorMessages: err.message };
        });
    },
    logout(state) {
      const userType = state.currentUser?.type || "none";
      state.dispatch("setCurrentUser", undefined);
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      api.defaults.headers.common["Authorization"] = undefined;
      if (userType === "teacher") {
        router.push({ name: "TeacherLogin" });
      } else {
        router.push({ name: "Login" });
      }
    },
    getAllCourses(state, request) {
      return api
        .get("/course", request)
        .then((res) => {
          state.commit("setStoreItem", ["allCourses", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getAllEnrollments(state, request) {
      return api
        .get("/enrollment", request)
        .then((res) => {
          state.commit("setStoreItem", ["allEnrollments", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getStudentInfo(state) {
      return api
        .get("/student")
        .then((res) => {
          state.dispatch("setCurrentUser", { ...res.data, type: "student" });
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getPaymentUrl(state, paymentTag) {
      return api
        .post("/payment/create", { paymentTag })
        .then((res) => {
          state.commit("setStoreItem", ["paymentUrl", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    continuePaymentSession(state) {
      return api
        .get("/payment/session")
        .then((res) => {
          state.commit("setStoreItem", ["paymentUrl", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    cancelPaymentSession(state) {
      return api
        .delete("/enrollment")
        .then((res) => {
          state.commit("setStoreItem", ["paymentUrl", undefined]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getStudentSubscription(state) {
      return api
        .get("/student/subscription")
        .then((res) => {
          state.commit("setStoreItem", ["studentSubscription", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );

          return err;
        });
    },
    submitNewsletter(state, email) {
      return api
        .post("/newsletter", email)
        .then(async (res) => {
          return res.data;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    createEnrollment(state, registration) {
      // {
      //   "courseId": "657dd807d4d17a1bb2df9b2f",
      //   "selectedDays": ["monday", "wednesday", "friday"],
      //   "startTime": "2023-12-17T14:30:00+00:00",
      //   "endTime": "2023-12-17T15:00:00+00:00"
      // }
      return api
        .post("/enrollment", registration)
        .then(async (res) => {
          await state.dispatch("getStudentInfo");
          state.commit("setStoreItem", ["paymentUrl", res.data]);
          return res.data;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getUserDetail(state) {
      return api.get("/users/me").then((res) => {
        if (res.data) {
          state.commit("setStoreItem", ["userDetail", res.data]);
        }
      });
    },
    teacherLogin(state, request) {
      return api
        .post("/teacher/signin", request)
        .then((res) => {
          if (res.status === 200) {
            const user = {
              type: "teacher",
              ...res.data.teacher,
            };
            api.defaults.headers.common["Authorization"] =
              "Bearer " + res.data.accessToken;
            localStorage.accessToken = res.data.accessToken;
            localStorage.user = JSON.stringify(user);
            state.dispatch("setCurrentUser", user);
            router.push({ name: "TeacherDashboard" });
            return true;
          }
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );

          return { errorMessages: err.message };
        });
    },
    submitContactForm(state, data) {
      return api
        .post("/contact", data)
        .then(() => {
          return true;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    submitClassFeedback(state, { enrollmentId, feedback }) {
      return api
        .post("/feedback/" + enrollmentId, { feedback })
        .then(() => {
          return true;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
  },
  modules: {},
  getters: {},
});
