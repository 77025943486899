<template>
  <div class="has-text-white has-text-centered footer-section-bg py-5">
    <div class="spacer-small"></div>
    <img
      src="@/assets/img/logo.svg"
      alt="TeachQuran"
      width="128px"
      style="filter: brightness(100)"
      class="mb-5"
    />
    <p class="is-subtitle-small is-bold mt-5">Join Us Today</p>
    <p class="is-small mt-4">
      Unlock the treasures of the Quran. Choose TeachQuran and let the journey
      of enlightenment begin.
    </p>
    <div class="columns mt-5">
      <div class="column has-text-centered">
        <a href="https://www.facebook.com/tq.teachquran" target="_blank"
          ><i class="mdi mdi-facebook"></i
        ></a>

        <a href="https://www.instagram.com/teachquranorg/" target="_blank"
          ><i class="mdi mdi-instagram ml-3"></i
        ></a>
        <a href="mailto:info@teach-quran.org" target="_blank"
          ><i class="mdi mdi-email-outline ml-3"></i
        ></a>
      </div>
    </div>
    <a
      class="has-text-success is-small mt-5"
      href="mailto:info@teach-quran.com"
    >
      info@teach-quran.com
    </a>
    <p class="has-text-success is-small my-5">
      <router-link
        :to="{ name: 'PrivacyPolicy' }"
        class="has-text-white is-small mr-5"
      >
        Privacy Policy
      </router-link>
      <router-link
        :to="{ name: 'TermsAndConditions' }"
        class="has-text-white is-small"
      >
        Terms and Conditions
      </router-link>
    </p>
    <div class="spacer-small"></div>
    <p class="is-small">Copyrights © Teach Quran. All Rights Reserved.</p>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.footer-section-bg {
  background: #102828;
  background: url("~@/assets/img/home/footer-bg.svg");
  background-size: cover;
}
</style>
