<template>
  <div id="app">
    <div class="hero-gradient-bg">
      <div class="spacer"></div>
      <div class="spacer"></div>
      <div class="columns is-vcentered is-desktop">
        <div class="column has-text-centered">
          <img
            src="@/assets/img/logo.svg"
            alt="TeachQuran"
            style="height: 72px"
          />

          <p class="is-subtitle py-4 m-1">
            We couldn't find the page you were looking for.
          </p>
          <div class="mt-4">
            <router-link :to="{ name: 'Home' }" class="is-dark-gray">
              <a class="button is-success">Go to Home</a>
            </router-link>
          </div>

          <div class="spacer"></div>
          <div class="spacer"></div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";

export default {
  name: "NotFound",
  components: { Footer },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
