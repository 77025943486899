import axios from "axios";
import Notification from "@/services/notificationService";
import store from "@/store";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL, //"https://teachquran-api-staging.onrender.com/api/v1/",
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Notification.error(`You must be logged in to continue`);
      store.dispatch("logout");
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export default api;
